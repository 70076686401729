var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CFooter", { attrs: { fixed: false } }, [
    _c("div", [
      _c("span", { staticClass: "ml-1" }, [
        _vm._v("© " + _vm._s(new Date().getFullYear()) + " Jogg, Inc.")
      ])
    ]),
    _c("div", { staticClass: "mfs-auto" }, [
      _c("span", { staticClass: "mr-1", attrs: { target: "_blank" } }, [
        _vm._v("Created by")
      ]),
      _c("a", { attrs: { href: "https://techpro.com" } }, [
        _vm._v("TechPro, Inc.")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }