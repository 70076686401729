var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CDropdown",
    {
      staticClass: "c-header-nav-items",
      attrs: { inNav: "", placement: "bottom-end", "add-menu-classes": "pt-0" },
      scopedSlots: _vm._u([
        {
          key: "toggler",
          fn: function() {
            return [
              _c("CHeaderNavLink", [
                _c("div", { staticClass: "pr-2" }, [
                  _vm._v(_vm._s(_vm.username))
                ]),
                _c("div", { staticClass: "c-avatar" }, [
                  _c("img", {
                    staticClass: "c-avatar-img ",
                    attrs: { src: _vm.profile_photo }
                  })
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "CDropdownHeader",
        { staticClass: "text-center", attrs: { tag: "div", color: "light" } },
        [_c("strong", [_vm._v("Account")])]
      ),
      _c(
        "CDropdownItem",
        {
          on: {
            click: function($event) {
              return _vm.$router.push("profile")
            }
          }
        },
        [_c("CIcon", { attrs: { name: "cil-user" } }), _vm._v(" Profile ")],
        1
      ),
      _c(
        "CDropdownItem",
        { on: { click: _vm.logout } },
        [
          _c("CIcon", { attrs: { name: "cil-lock-locked" } }),
          _vm._v(" Logout ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }