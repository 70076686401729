var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CHeader",
    { attrs: { "with-subheader": "" } },
    [
      _c("CToggler", {
        staticClass: "ml-3 d-lg-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarMobile")
          }
        }
      }),
      _c("CToggler", {
        staticClass: "ml-3 d-md-down-none",
        attrs: { "in-header": "" },
        on: {
          click: function($event) {
            return _vm.$store.commit("toggleSidebarDesktop")
          }
        }
      }),
      _c("CHeaderBrand", {
        staticClass: "mx-auto d-lg-none",
        attrs: {
          src: "img/jogg-logo-white.svg",
          width: "190",
          height: "46",
          alt: "Jogg Logo"
        }
      }),
      _c("CHeaderNav", { staticClass: "d-md-down-none mr-auto" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("CHeaderNav", [_c("TheHeaderDropdownAccnt")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }